import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {Typography} from '@mui/material';

const StaticCircularProgress = ({ value, label }) => {
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress
          variant="static"
          value={value}
          thickness={5} // Adjust the thickness as needed
        />
        <Typography variant="h6" color="textSecondary">
          {label}
        </Typography>
      </div>
    );
  };
  
  export default StaticCircularProgress;