import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';

export default function ButtonAppBar() {

    const location = useLocation();
    // const [userName, setUserName] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [userData, setUserData] = useState({});
    

    useEffect(() => {
        const fetchUserData = async () => {
        try {
            const response = await axios.get('/app/user_data', {
            withCredentials: true,
            });
            if (response.status === 200) {
                // const userData = response.data;
                setUserData(response.data);
                // setUserName(userData.username);
            }
        } catch (error) {
            console.log("Could now retrieve user data!")
        }
        };
        fetchUserData();
    }, []);

    const handleLogout = async () => {
        try {
        await axios.post('/app/logout', null, { withCredentials: true });
        // Clear any client-side session or local storage as needed
        window.location = '/';
        } catch (error) {
        console.error('Logout failed:', error);
        }
    };
    

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
        console.log(userData);
        console.log(userData.userName);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={{color:"white", backgroundColor:"0079bf"}}>
            <Toolbar>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={toggleDrawer} // Add onClick handler to toggle drawer
            >
                <MenuIcon />
            </IconButton>

            <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={toggleDrawer} // Add onClose handler to close drawer
            >
                <List>
                <ListItem>
                    <ListItemText
                    primary={
                        <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#0079bf'}}>
                        Media Scanner
                        </Typography>
                    }
                    />
                </ListItem>
                <Divider />
                <ListItemButton component={Link} to="/main">
                    <ListItemText primary="Pretraga uživo" />
                </ListItemButton>
                    {(userData.alarm_limit > 0) && ( // Conditionally render based on userId that corresponds to system admin
                        <ListItemButton component={Link} to="/alarms">
                            <ListItemText primary="Alarmi" />
                        </ListItemButton>
                    )}
                </List>
            </Drawer>
            
            <Container>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Media Scanner 
                </Typography>
            </Container>

            <AccountCircleIcon ></AccountCircleIcon>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
            >
                <MenuItem onClick={handleLogout}>Odjava</MenuItem>
            </Menu>
            <Button onClick={handleMenu} color="inherit">{userData['username']}</Button>
            </Toolbar>
        </AppBar>
        </Box>
    );
}