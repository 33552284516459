import React, { useState, useEffect } from 'react';
import axios from 'axios';
import StarIcon from '@mui/icons-material/Star';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import Container from '@mui/material/Container';
import NavHeader from './NavHeader'
import { FormGroup, FormControlLabel, Checkbox, Button, LinearProgress, Snackbar, TextField, Switch, Alert, AlertTitle } from '@mui/material';
import {Divider} from '@mui/material';
import Modal from '@mui/material/Modal';
import {Typography} from '@mui/material';
import {Box} from '@mui/material';
import {WithContext as ReactTags} from 'react-tag-input'
import { TERMS } from '../terms';
import '../MainScreen.css';
import {useNavigate} from 'react-router-dom';

let apiPrefix = ''
const isLocal = window.location.hostname === 'localhost';
if (isLocal) {
  console.log("React is running locally");
  // apiPrefix = 'http://localhost:5000';
  apiPrefix = ''
} else {
  console.log("React is running on DigitalOcean");
  apiPrefix = '/app'
}

const suggestions = TERMS.map((term) => {
  return {
    id: term,
    text: term,
  };
});

const KeyCodes = {
  comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

function MainScreen() {

  //const [inputText, setInputText] = useState('');
  const navigate = useNavigate();

  // checking if user is authenticated
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        console.log("Calling "+apiPrefix+"/check_authentication...");
        const response = await axios.get(apiPrefix + '/check_authentication', {
          withCredentials: true,
        });
        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          navigate('/'); // Navigate to login if not authenticated
        }
      } catch (error) {
        navigate('/'); // Navigate to login on error
      }
    };
    checkAuthentication();
  }, [navigate]);

  const allTags = [];

  const [userId, setUserId] = useState('');
  const [tags, setTags] = React.useState(allTags);
  const [allowedPortals, setAllowedPortals] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState({});
  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(apiPrefix + '/user_data', {
          withCredentials: true,
        });
        if (response.status === 200) {
          console.log("Fetched user data: ", response.data);
          setUserId(response.data.user_id);
          return response.data.user_id; // Return the user ID
        }
      } catch (error) {
        console.log("Could not retrieve user data!")
        throw error;
      }
    };

    const fetchData = async (userId) => {
      try {
        console.log('fetching terms data with user_id: ', userId);
        const response = await axios.post(apiPrefix + '/get_terms_by_userid', {
          user_id: userId,
        }, {
          withCredentials: true,
        });
        setTags(response.data);
        return userId;
      } catch (error) {
        console.error('Error getting terms by user_id:', error);
      }
    };

    const fetchAllowedPortals = async (userId) => {
      try {
        console.log('fetching allowed portals data with user_id: ', userId);
        const response = await axios.post(apiPrefix + '/get_allowed_portals_for_user_id', {
          user_id: userId,
        }, {
          withCredentials: true,
        });
        console.log("Response of fetchAllowedPortals: ", response.data);
        const returnedAllowedPortals = response.data.map(portal => ({
          name: portal.name,
          url: portal.url,
          source_name: portal.source_name
        }));
        setAllowedPortals(returnedAllowedPortals);
        // console.log("Allowed portals: ", allowedPortals)
        const enlistedPortals = response.data.reduce((acc, portal) => {
          acc[portal.source_name] = true;
          // THIIIISSSSSS is weird... return beneath
          return acc;
        }, {});
        setCheckboxValues(enlistedPortals);
      } catch (error) {
        console.error('Error getting allowed portals:', error);
      }
    };

    fetchUserData()
      .then((userId) => fetchData(userId))
      .then((userId) => fetchAllowedPortals(userId))
      .catch((error) => console.error('Error:', error));
  }, []); // Empty dependency array indicates this effect will run only once on mount

  const fetchData = async (userId) => {
    try {
      console.log('fetching data with user_id: ', userId);
      const response = await axios.post(apiPrefix + '/get_terms_by_userid', {
        user_id: userId,
      }, {
        withCredentials: true,
      });
      setTags(response.data);
      return userId;
    } catch (error) {
      console.error('Error getting terms by user_id:', error);
    }
  };



  const [formData, setFormData] = useState('');   // use this to store findings
  const [responseMessage, setResponseMessage] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [successfullAPICalls, setSuccessfullAPICalls] = React.useState(0);
  const [sourcesListLength, setSourcesListLength] = React.useState(0);
  const [sourceInSearch, setSourceInSearch] = React.useState('');
  const [allFindings, setAllFindings] = useState([]);
  //const [allItems, setAllItems] = useState('');
  const [open, setModalOpen] = React.useState(false);
  //const [snackOpen, setSnackOpen] = React.useState(false);
  const [num, setNum] = React.useState(24);
  
  const [checkboxAll, setCheckboxAll] = useState(true)

  const [switchChecked, setSwitchChecked] = React.useState(true);
  
  const handleSwitchChange = (event) => {
    setSwitchChecked(event.target.checked);
    if (event.target.checked) {
      fetchData(userId);
    } else {
      setTags([])
    }
  };
  
  const [termSetChangeHappened, setTermSetChangeHappened] = useState(false);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
    setTermSetChangeHappened(true);
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
    setTermSetChangeHappened(true);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  const handleCheckboxChange = (event) => {
    setCheckboxValues({
      ...checkboxValues,
      [event.target.name]: event.target.checked,
    });
  };

  const handleCheckboxAll = (event) => {
    const newCheckboxValues = Object.keys(checkboxValues).reduce((acc, key) => {
      acc[key] = event.target.checked;
      return acc;
    }, {});
    setCheckboxAll(event.target.checked);
    setCheckboxValues(newCheckboxValues);
  };
  

  const [errorMessage, setErrorMessage] = useState("");
  ///////////////////////////////////////////////////////
  ///////////  SUBMIT one by one - version 2  ///////////
  ///////////////////////////////////////////////////////
  const handleSubmit3 = async (event) => {
    event.preventDefault();
    setSearchStatus("Initialized");
    setModalOpen(true)

    lemmatize_terms();
    
    // var payload = preparePayload();
    // var sources = payload.sources;
    // var payload2 = {    // this is a specific payload, specific for each API call separately. Used as a template for each call
    //   "source": null,
    //   "emailAddresses": payload.emailAddresses,
    //   // "terms": payload.terms,
    //   "terms": lemmatized_terms,
    //   "freshness": payload.freshness,  // should convert to a number type
    // }
    // var findings = [];
    // var lenSources = sources.length;
    // setSourcesListLength(lenSources);

    // var successCallsCounter = 0;
    // setSuccessfullAPICalls(0);
    // console.log("SuccesAPICalls when initialized:", successCallsCounter);
    // console.log("Payload za pretragu tik prije API poziva: ", payload2);
    // await makeRecursiveApiCall(sources, payload2, successCallsCounter, lenSources, findings);

  }

  useEffect(() => {
    console.log('Source in search:', sourceInSearch);
  }, [sourceInSearch]);


  function makeRecursiveApiCall(sources, payload, successCallsCounter, lenSources, findings) {
      setSuccessfullAPICalls(prevSuccessfullAPICalls => prevSuccessfullAPICalls + 1);
      if (sources.length === 0) {
          console.log('All API calls finished!');
          setSourceInSearch('');
          console.log("See findings: ", findings);
          
          console.log("Findings pre-navigate: ", findings);
          navigate('/resultFilter', {state: {findings}});
          return findings;
      } else {
          const nextSource = sources.shift();
          var payload2 = {
            "source": nextSource,
            "emailAddresses": payload.emailAddresses,
            "terms": payload.terms,
            "freshness": payload.freshness,
          }
          console.log("Searching ", nextSource);

          // in allowedPortals find the name of the portal for the source == nextSource
          for (let i=0; i<allowedPortals.length; i++) {
            if (allowedPortals[i].source_name === nextSource) {
              // set the sourceInSearch to allowedPortals[i].portalName
              setSourceInSearch(allowedPortals[i].name);
              break;
            }
          }
          
          return axios.post(apiPrefix + '/searchTerms3', { payload2 })
          // return axios.post('http://localhost:6000', { payload2 })
            .then((response) => {
              console.log("API call to *"+nextSource+"* finished with response:", response.data);
              if (response.status === 200) {
                successCallsCounter = successCallsCounter + 1;
                for (let i=0; i<response.data.length; i++) {
                  findings.push(response.data[i]);
                }
              }
              return makeRecursiveApiCall(sources, payload2, successCallsCounter, lenSources, findings);
            })
            .catch((error) => {
              console.error("Error in API call:", error);
              setSearchStatus("Failed");
              setErrorMessage(error.message);
            });
      }
  }

  function preparePayload() {
    // prepare source list
    var sources = [];
    for (const key in checkboxValues) {
      if (checkboxValues[key]) {
        sources.push(key);
      }
    }
    var payload = {
      sources: sources,
      // "emailAddresses": allEmailAddresses.map(({ text }) => text),
      terms: tags.map(({ text }) => text),
      // terms: lemmatized_terms,
      freshness: +num, // should convert to a number type
    };
    // console.log("Lemmatizirani pojmovi: ", payload.terms);
    return payload;
  }

  const [lemmatized_terms, setLemmatized_terms] = useState("");

  const lemmatize_terms = async () => {
    var terms = tags.map(({ text }) => text);
    var payload = {
      "terms": terms
    }
    // call API on the backend and wait for the response
    setSearchStatus("Initializing");
    console.log("Calling API <lemmatize_terms> with payload:", payload);
    try {
      const response = await axios.post(apiPrefix + '/lemmatize_terms', { payload });
      console.log("API call to <lemmatize_terms> finished with response:", response.data);
      
      if (response.status === 200) {
        console.log("Response data before setLemmtized_terms: ", response.data);
        setLemmatized_terms(response.data);
        setSearchStatus("Initialized");
      }
    } catch (error) {
      console.error("Error in API call to <lemmatize_terms>:", error);
      setSearchStatus("Failed");
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    if (lemmatized_terms) {
      var payload = preparePayload();
      var sources = payload.sources;
      var payload2 = {
        "source": null,
        "emailAddresses": payload.emailAddresses,
        "terms": lemmatized_terms,  // Use lemmatized_terms directly
        "freshness": payload.freshness,
      };
      var findings = [];
      var lenSources = sources.length;
      setSourcesListLength(lenSources);
  
      var successCallsCounter = 0;
      setSuccessfullAPICalls(0);
      console.log("SuccesAPICalls when initialized:", successCallsCounter);
      console.log("Payload za pretragu tik prije API poziva: ", payload2);
  
      makeRecursiveApiCall(sources, payload2, successCallsCounter, lenSources, findings);
    }
  }, [lemmatized_terms]);
  
  

  function searchButtonEnabled() {
    const hasSelectedCheckbox = Object.values(checkboxValues).some(value => value === true);
    const hasTags = tags && tags.length > 0;
    return hasSelectedCheckbox && hasTags;
  }

  function handleSetDefaultUserTerms() {
    // collect allTags and make a list of string names from text properties
    var defaultUserTerms = [];
    for (let i=0; i<tags.length; i++) {
      defaultUserTerms.push(tags[i].text);
    }
    // call /setDefaultUserTerms with POST on backend
    // console.log("This is payload for /setDefaultUserTerms", defaultUserTerms);
    axios.post(apiPrefix + '/setDefaultUserTerms', {'list_new_terms': defaultUserTerms})
    .then((response) => {
      console.log("API call to <setDefaultUserTerms> finished with response:", response.data);
      if (response.status === 200) {
        setTermSetChangeHappened(false);
      }
      else {
        setTermSetChangeHappened(true);
        console.log("Error in API call:", response.data);
      }
    })
  }

  function handleCloseModal(){
    setModalOpen(false);
    setResponseMessage('');
    setSearchStatus('');
    setSuccessfullAPICalls(0);
  }

  const alertComponent = {
    Initializing: <Box sx={box}><Box><Typography id="modal-modal-title" variant="h6" component="h2" >Pripremam pretragu ...</Typography><br/><br/><LinearProgress /></Box></Box>,
    Initialized: <Box sx={box}><Box><Typography id="modal-modal-title" variant="h6" component="h2" >Pretražujem {sourceInSearch}... ({successfullAPICalls}/{sourcesListLength})</Typography><br/><br/><LinearProgress /></Box></Box>,
    Failed: <Box sx={box}><Box><Alert severity="error"> <AlertTitle>Dogodila se greška u traženju na portalu {sourceInSearch}: {errorMessage}</AlertTitle></Alert><br/><Button onClick={handleCloseModal}>Zatvori</Button></Box></Box>,
    Partial: <Box sx={box}><Box><Alert severity="warning"> <AlertTitle>Djelomično</AlertTitle>Pretraga je bila djelomično uspješna</Alert><br/><Button onClick={handleCloseModal}>Zatvori</Button></Box></Box>,
    Complete: <Box sx={box}><Box><Alert severity="success"> <AlertTitle>Gotovo!</AlertTitle>Pretraga je završila — <strong>Rezultati su u inboxu!</strong></Alert><br/><Button onClick={handleCloseModal}>Zatvori</Button></Box></Box>,
  }[searchStatus] || <Box sx={box}><Box><Alert severity="error"> <AlertTitle>Dogodila se nepoznata greška</AlertTitle></Alert><br/><Button onClick={handleCloseModal}>Zatvori</Button></Box></Box>;

  return (
    <div>
      <form onSubmit={handleSubmit3}>
        <NavHeader/>
        <Container style={{...style, display:"flex", flexDirection:"column", justifyContent:"space-evenly", font:"Amalia"}}>

        {Object.keys(checkboxValues).length > 0 && allowedPortals.length > 0 && (
          <div>
            <Container style={{...style, paddingLeft:20}}>
            <FormGroup>
              <div style={{paddingTop:"15px", paddingBottom:"10px"}}>Odaberi portale za pregled:</div>
              <FormControlLabel control={<Checkbox />} label="Svi portali"
                  name="sviportali"
                  checked={checkboxAll}
                  onChange={handleCheckboxAll}/>
              <br/>

              {Object.keys(checkboxValues).map(key => (
                <FormControlLabel
                  key={key}
                  control={<Checkbox />}
                  label={allowedPortals.find(portal => portal.source_name === key)?.name || ""}
                  name={key}
                  checked={checkboxValues[key]}
                  onChange={handleCheckboxChange}
                />
              ))}
              
              
            </FormGroup>
          </Container>
          </div>
        )}

          <Divider style={{...style}}/>

          <Container style={{...style, paddingTop:"10"}}>
            <div>
              
              <p>Traženi pojmovi:</p>

              <Switch
                onChange={handleSwitchChange}
                checked={switchChecked}
                inputProps={{ 'aria-label': 'controlled' }}
                label="Uobičajeni pojmovi za pretragu"
              />
              <div>
                <ReactTags 
                  tags={tags}
                  suggestions={suggestions}
                  delimiters={delimiters}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  handleTagClick={handleTagClick}
                  inputFieldPosition="bottom"
                  placeholder='Unesi pojam, zatim zarez ili Enter'
                  autocomplete
                  editable
                />
              </div>

              <Container
                style={{...style, paddingTop:10}}>
                {termSetChangeHappened ?
                  <Button 
                    variant="contained"
                    startIcon={<StarIcon/>}
                    onClick={() => handleSetDefaultUserTerms()}>
                    Zapamti pojmove
                  </Button>
                  :
                  <Button 
                    variant="contained"
                    startIcon={<StarIcon/>}
                    disabled>
                    Zapamti pojmove
                  </Button>
                }
                
              </Container>
            </div>
          </Container>

          <Divider style={{...style}}/>

          <Container
            style={{...style, paddingTop:"10px"}}>
            <p>Objavljeno prije (h):</p>
              <TextField
                style={{width:100}}
                type="number"
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => setNum(e.target.value)}
                value={num}
              />
          </Container>
            

          <Container
            style={{...style, paddingTop:50}}>
            {searchButtonEnabled() ?
              <Button type="submit"
              variant="contained"
              startIcon={<FindInPageIcon/>}
              //onChange={(event) => setInputText(event.target.value)}
              >Traži</Button>
              :
              <Button type="submit"
              variant="contained"
              startIcon={<FindInPageIcon/>}
              disabled>Traži</Button>
            }
            
          </Container>

        </Container>
        
      </form>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {alertComponent}
      </Modal>

      
    </div>
  );
}

const style = {
  padding: "10px",
  //border: "1px solid rgba(0, 0, 0, 0.12)",
  //borderRadius: 4,
  fontFamily: "Arial",
  fontSize: "15px",
};

const box =  {
  
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

export default MainScreen;
