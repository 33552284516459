import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginScreen from "./Components/LoginScreen";
import MainScreen from "./Components/MainScreen";
import ResultFilterScreen from "./Components/ResultFilterScreen";
import Alarms from "./Components/Alarms";
// import ScheduledSearches from "./Components/ScheduledSearches.js";
import AlarmDetails from "./Components/AlarmDetails.js";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginScreen />} />
        <Route path="/main" element={<MainScreen />} />
        <Route path="/resultFilter" element={<ResultFilterScreen />} />
        <Route path="/alarms" element={<Alarms/>} />
        {/* <Route path="/scheduledSearches" element={<ScheduledSearches/>} /> */}
        <Route path="/alarmDetails" element={<AlarmDetails/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;


/* import React from "react";
//import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Router, Routes ,Route, Link } from 'react-router-dom';
import LoginScreen from "./Components/LoginScreen";
import MainScreen from "./Components/MainScreen";

const App = () => {
  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/login">Login</Link>
            </li>
            <li>
              <Link to="/mainScreen">MainScreen</Link>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route path="/mainScreen">
            <MainScreen />
          </Route>
          <Route path="/login">
            <LoginScreen />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default App; */