import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import SendIcon from '@mui/icons-material/Send';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import axios from 'axios';
import Container from '@mui/material/Container';
import { FormGroup, FormControlLabel, Checkbox, Button, Divider, Switch, Box, Alert, AlertTitle, Tooltip } from '@mui/material';
import {WithContext as ReactTags} from 'react-tag-input';
import Modal from '@mui/material/Modal';
import NavHeader from './NavHeader';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import StaticCircularProgress from './StaticCircularProgress';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import {Typography} from '@mui/material';

let apiPrefix = ''
const isLocal = window.location.hostname === 'localhost';
if (isLocal) {
    console.log("React is running locally");
    // apiPrefix = 'http://localhost:5000';
    apiPrefix = ''
} else {
    console.log("React is running on DigitalOcean");
    apiPrefix = '/app'
}

const ResultFilterScreen = () => {
    
    const location = useLocation();

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    useEffect(() => {
        const checkAuthentication = async () => {
        try {
            const response = await axios.get(apiPrefix + '/check_authentication', {
                withCredentials: true,
            });
            if (response.status === 200) {
                setIsAuthenticated(true);
            } else {
                window.location = '/'; // Redirect to login if not authenticated
            }
        } catch (error) {
            window.location = '/'; // Redirect to login on error
        }
        };
        checkAuthentication();
    }, [location]);

    const handleGoBack = () => {
        // Build the path for the /main page
        const mainPath = '/main';

        // Navigate to the /main page using the path
        window.location = mainPath;
    };

    // const items = location.state && location.state.findings ? location.state.findings : [];
    const [selectedItems, setSelectedItems] = useState(location.state && location.state.findings ? location.state.findings : []);
    
    // const [selectedItems, setSelectedItems] = useState(location.state.findings);
    const KeyCodes = {
        comma: 188,
        enter: 13,
    };
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const initialState = Object.fromEntries(selectedItems.map((item) => [item.url, true]));

    // 2024-04-02 mstrkalj added
    const [sourceCheckboxes, setSourceCheckboxes] = useState({});

    const [checkboxValues, setCheckboxValues] = useState(initialState);

    // 2024-04-02 mstrkalj added: item in definition and all below first setCheckboxValues block
    const handleCheckboxChange = (event, item) => {
        setCheckboxValues({
            ...checkboxValues,
            [event.target.name]: event.target.checked,
        });

        // 2024-04-02 mstrkalj added
        const allItemsInSource = selectedItems.filter((i) => i.source === item.source);
        const isAllChecked = allItemsInSource.every((i) => i.url === event.target.name ? event.target.checked : checkboxValues[i.url]);
        const isNoneChecked = allItemsInSource.every((i) => i.url === event.target.name ? !event.target.checked : !checkboxValues[i.url]);

        setSourceCheckboxes({
            ...sourceCheckboxes,
            [item.source]: isNoneChecked ? false : isAllChecked ? true : 'indeterminate',
        });
    };

    // 2024-04-02 mstrkalj added
    const handleSourceCheckboxChange = (source) => {
        const isCurrentlyChecked = sourceCheckboxes[source];
        const newCheckState = !isCurrentlyChecked;

        const newCheckboxValues = { ...checkboxValues };
        selectedItems.forEach((item) => {
            if (item.source === source) {
                newCheckboxValues[item.url] = newCheckState;
            }
        });

        setCheckboxValues(newCheckboxValues);
        setSourceCheckboxes({
            ...sourceCheckboxes,
            [source]: newCheckState,
        });
    };

    const [checkboxAll, setCheckboxAll] = useState(true)
        
    // 2024-04-02 mstrkalj replaced the below block with the one after it

    // const handleCheckboxAll = (event) => {
    //     console.log("Clicked on checkbox all");
    //     setCheckboxAll(event.target.checked);
    //     // toggleAllCheckboxValues();
    //     setCheckboxValues((prevValues) =>
    //         Object.fromEntries(Object.keys(prevValues).map((key) => [key, !checkboxAll]))
    //     );
    // }

    const handleCheckboxAll = (event) => {
        const newCheckState = event.target.checked;
        const newCheckboxValues = {};
        const newSourceCheckboxes = {};

        selectedItems.forEach((item) => {
            newCheckboxValues[item.url] = newCheckState;
            newSourceCheckboxes[item.source] = newCheckState;
        });

        setCheckboxValues(newCheckboxValues);
        setCheckboxAll(newCheckState);
        setSourceCheckboxes(newSourceCheckboxes);
    };

    const [userId, setUserId] = useState('');
    const [allEmailAddresses, setAllEmailAddresses] = React.useState([]);

    // 2024-04-02 mstrkalj added
    useEffect(() => {
        const initialCheckboxValues = {};
        const initialSourceCheckboxes = {};

        selectedItems.forEach((item) => {
            initialCheckboxValues[item.url] = true;
            initialSourceCheckboxes[item.source] = true;
        });

        setCheckboxValues(initialCheckboxValues);
        setSourceCheckboxes(initialSourceCheckboxes);
    }, [selectedItems]);

    useEffect(() => {
        const fetchUserData = async () => {
          try {
            const response = await axios.get(apiPrefix + '/user_data', {
              withCredentials: true,
            });
            if (response.status === 200) {
              console.log("Fetched user data: ", response.data);
              setUserId(response.data.user_id);
              return response.data.user_id; // Return the user ID
            }
          } catch (error) {
            console.log("Could not retrieve user data!")
            throw error;
          }
        };
    
        const fetchUserDefaultEmailAddresses = async (userId) => {
          try {
            console.log('fetching user default email addresses with user_id: ', userId);
            const response = await axios.post(apiPrefix + '/get_user_default_email_addresses_by_user_id', {
              user_id: userId,
            }, {
              withCredentials: true,
            });
            setAllEmailAddresses(response.data);
            // return userId;
          } catch (error) {
            console.error('Error getting email addresses by user_id:', error);
          }
        };
        
        // 2024-04-02 mstrkalj: recognized this... maybe should be deleted
        selectedItems.forEach((item) => {
            if (!checkboxValues.hasOwnProperty(item.source)) {
                setCheckboxValues((prevState) => ({
                    ...prevState,
                    [item.source]: true
                }));
            }
        });

        fetchUserData()
          .then((userId) => fetchUserDefaultEmailAddresses(userId))
          .catch((error) => console.error('Error:', error));
    }, []); // Empty dependency array indicates this effect will run only once on mount

    const fetchUserDefaultEmailAddresses = async (userId) => {
        try {
          console.log('fetching user default email addresses with user_id: ', userId);
          const response = await axios.post(apiPrefix + '/get_user_default_email_addresses_by_user_id', {
            user_id: userId,
          }, {
            withCredentials: true,
          });
          setAllEmailAddresses(response.data);
        //   return userId;
        } catch (error) {
          console.error('Error getting terms by user_id:', error);
        }
    };

    const [emailSuggestions] = React.useState([
        {id: 'josipabnm@gmail.com', text: 'josipabnm@gmail.com'},
        {id: 'josipa.strkalj@rmf.hr', text: 'josipa.strkalj@rmf.hr'},
        {id: 'marko.strkalj@gmail.com', text: 'marko.strkalj@gmail.com'},
        {id: 'bnmjosipa@yahoo.com', text: 'bnmjosipa@yahoo.com'},
    ]);

    

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Create a new array to hold items to be sent
        const itemsForSending = selectedItems.filter(item => checkboxValues[item.url]);

        console.log("Selected items length: ", itemsForSending.length);
        console.log("Selected items: ", itemsForSending);

        sendEmails(itemsForSending);
    };

    const [emailAddressesSetChangeHappened, setEmailAddressesSetChangeHappened] = useState(false);

    const handleDeleteEmail = (i) => {
        setAllEmailAddresses(allEmailAddresses.filter((tag, index) => index !== i));
        setEmailAddressesSetChangeHappened(true)
    };

    const handleAdditionEmail = (tag) => {
        setAllEmailAddresses([...allEmailAddresses, tag]);
        setEmailAddressesSetChangeHappened(true)
    };

    const handleDragEmail = (tag, currPos, newPos) => {
        const newTags = allEmailAddresses.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setAllEmailAddresses(newTags);
    };

    const handleTagClickEmail = (index) => {
        console.log('The emailAddress at index ' + index + ' was clicked');
    };

    // const [switchChecked, setSwitchChecked] = React.useState(true);
    const [switchCheckedEmail, setSwitchCheckedEmail] = React.useState(true);

    const handleSwitchChangeEmail = (event) => {
        setSwitchCheckedEmail(event.target.checked);
        if (event.target.checked) {
            fetchUserDefaultEmailAddresses(userId);
        } else {
            setAllEmailAddresses([])
        }
    };

    function handleSetDefaultEmailAddresses() {
        // collect allTags and make a list of string names from text properties
        var defaultEmailAddresses = [];
        for (let i=0; i<allEmailAddresses.length; i++) {
            defaultEmailAddresses.push(allEmailAddresses[i].text);
        }
        // call /setDefaultEmailAddresses with POST on backend
        console.log("This is payload for /setDefaultEmailAddresses", defaultEmailAddresses);
        axios.post(apiPrefix + '/setDefaultEmailAddresses', {'default_email_addresses': defaultEmailAddresses})
        .then((response) => {
          console.log("API call to <setDefaultEmailAddresses> finished with response:", response.data);
          if (response.status === 200) {
            setEmailAddressesSetChangeHappened(false);
          }
          else {
            setEmailAddressesSetChangeHappened(true);
            console.log("Error in API call:", response.data);
          }
        })
      }

    const sendButtonEnabled = () => {
        // if all checkboxValues are false then return false, else return true
        return Object.values(checkboxValues).some((value) => value === true) && (allEmailAddresses.length > 0);
    }

    

    function sendEmails(findings) {
        var payload = {
        "emailAddresses": allEmailAddresses.map(({ text }) => text),
        "findings": findings,
        }
        axios.post(apiPrefix + '/sendEmails', { payload })
        .then((response) => {
            console.log("API call to <sendEmail> finished with response:", response.data);
            if (response.status === 200) {
            console.log("Emails sent successfully");
            setSearchStatus("Complete");
            setModalOpen(true);
            }
        })
        .catch((error) => {
            console.error(error);
            setErrorMessage(error.message)
            setSearchStatus("Failed");
            setModalOpen(true);
        });
    }

    let currentSource = null; // Initialize the currentSource to null

    // Modal 
    const [open, setModalOpen] = React.useState(false);
    function handleCloseModal(){
        setModalOpen(false);
    }

    // HTML Tooltip
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 220,
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #dadde9',
        },
      }));

    const [errorMessage, setErrorMessage] = useState("");
    const [searchStatus, setSearchStatus] = useState('');

    const alertComponent = {
        Complete: <Box sx={box}><Box><Alert severity="success"> <AlertTitle>Gotovo!</AlertTitle>Pretraga je završila — <strong>Rezultati su u inboxu!</strong></Alert><br/><Button onClick={handleCloseModal}>Zatvori</Button></Box></Box>,
        Failed: <Box sx={box}><Box><Alert severity="error"> <AlertTitle>Dogodila se greška: {errorMessage}</AlertTitle></Alert><br/><Button onClick={handleCloseModal}>Zatvori</Button></Box></Box>
    }[searchStatus] || <Box sx={box}><Box><Alert severity="error"> <AlertTitle>Dogodila se nepoznata greška</AlertTitle></Alert><br/><Button onClick={handleCloseModal}>Zatvori</Button></Box></Box>;

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <NavHeader/>
                <Container style={{...style, display:"flex", flexDirection:"column", justifyContent:"space-evenly", font:"Amalia"}}>

                
                        <Container style={{...style, paddingLeft:20}}>
                            <FormGroup>
                                <div style={{paddingTop:"15px", paddingBottom:"10px"}}>Odaberi članke za distribuciju:</div>
                                <FormControlLabel control={<Checkbox />} label="Svi portali"
                                    name="sviportali"
                                    checked={checkboxAll}
                                    onChange={handleCheckboxAll}/>
                                <br/>
                                
                                {selectedItems.map((item) => {
                                    const { url, title, source, first_term_found } = item;

                                    // Check if the source of the current item is different from the previous one
                                    const isNewSource = source !== currentSource;
                                    currentSource = source; // Update the current source

                                    return (
                                        <div key={url}>
                                            {/* Render the source title if it's a new source */}

                                            {/* 2024-04-02 mstrkalj replaced the blocks */}

                                            {/* {isNewSource && (
                                                <div>
                                                    <Checkbox
                                                        // checked={isNewSource}
                                                        checked = {checkboxValues[source]}
                                                        onChange={(event) => setSourceCheckbox(event.target.checked)}
                                                    />
                                                    <h3>{source}</h3>
                                                </div>
                                            )} */}

                                            {isNewSource && (
                                                // 2024-04-02 mstrkalj swapped blocks

                                                // <div style={{ display: 'flex', alignItems: 'left', marginBottom: '10px' }}>
                                                    
                                                //     <Checkbox
                                                //         checked={sourceCheckboxes[source] === true}
                                                //         indeterminate={sourceCheckboxes[source] === 'indeterminate'}
                                                //         onChange={() => handleSourceCheckboxChange(source)}
                                                //         name={source}
                                                //     />
                                                //     <h3 style={{ margin: '0 0 0 10px' }}>{source}</h3>
                                                // </div>

                                                <div>
                                                    <FormControlLabel 
                                                        control={
                                                            <Checkbox
                                                                checked={sourceCheckboxes[source] === true}
                                                                indeterminate={sourceCheckboxes[source] === 'indeterminate'}
                                                                onChange={() => handleSourceCheckboxChange(source)}
                                                                name={source}
                                                            />
                                                        }
                                                        label={<h3 style={{ marginLeft: '10px', margin: 0, fontWeight: 'bold' }}>{source}</h3>}
                                                        style={{marginTop: '30px', marginBottom: '10px' }}
                                                    />
                                                </div>
                                            )}

                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={
                                                    <div>
                                                        {title}
                                                        <br/>
                                                        <a href={url} target="_blank" rel="noopener noreferrer" style={{ fontSize: '12px' }}>
                                                            {url}
                                                        </a>
                                                    </div>
                                                }
                                                name={item.url}
                                                checked={checkboxValues[item.url]}
                                                onChange={handleCheckboxChange}/>
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <Typography color="inherit">{first_term_found}</Typography>
                                                        {/* <StaticCircularProgress value={item.value} label={item.label} /> */}
                                                    </React.Fragment>
                                                }
                                                placement="right"
                                                arrow>
                                                <HelpOutlineIcon
                                                    // onClick={handleHelpIconClick}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </HtmlTooltip>
                                            <br/>
                                            <br/>
                                        </div>
                                    )
                                    
                                })}
                                <br />
                            </FormGroup>
                        </Container>

                        <Divider style={{...style}}/>

                        
                        <Container style={{...style, paddingTop:"15"}}>
                            <div>
                                <p>Email adrese za dostavu rezultata pretrage:</p>
                                <Switch
                                    onChange={handleSwitchChangeEmail}
                                    checked={switchCheckedEmail}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    label="Uobičajene email adrese"
                                />
                                <div>
                                    <ReactTags
                                        tags={allEmailAddresses}
                                        suggestions={emailSuggestions}
                                        delimiters={delimiters}
                                        handleDelete={handleDeleteEmail}
                                        handleAddition={handleAdditionEmail}
                                        handleDrag={handleDragEmail}
                                        handleTagClick={handleTagClickEmail}
                                        inputFieldPosition="bottom"
                                        placeholder='Unesi email adresu, zatim zarez ili Enter'
                                        autocomplete
                                        editable
                                    />
                                </div>

                                <Container
                                    style={{...style, paddingTop:10}}>
                                    {emailAddressesSetChangeHappened ?
                                        <Button 
                                            variant="contained"
                                            startIcon={<StarIcon/>}
                                            onClick={() => handleSetDefaultEmailAddresses()}>
                                            Zapamti email adrese
                                        </Button>
                                        :
                                        <Button 
                                            variant="contained"
                                            startIcon={<StarIcon/>}
                                            disabled>
                                            Zapamti email adrese
                                        </Button>
                                    }
                                    
                                </Container>

                            </div>
                        </Container>

                        <Container style={{...style, paddingTop:50}}>
                            {
                                sendButtonEnabled() ? 
                                <Button type="submit"
                                    variant="contained"
                                    startIcon={<SendIcon/>}
                                    >
                                    Pošalji
                                </Button>
                                :
                                <Button type="submit"
                                    variant="contained"
                                    startIcon={<SendIcon/>}
                                    disabled
                                    // onClick={sendEmails(allEmailAddresses)}
                                    >
                                    Pošalji
                                </Button>   
                            }
                        </Container>
                    
                    
                </Container>
            </form>

            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {alertComponent}
            </Modal>

            <br />
            <Container style={{...style, paddingTop:50}}>
                
                <Button
                    onClick={handleGoBack}
                    startIcon={<ArrowBackIosNewIcon/>}>
                    Reset
                </Button>
                
            </Container>
            
        </div>
    );
};

const style = {
    padding: "10px",
    //border: "1px solid rgba(0, 0, 0, 0.12)",
    //borderRadius: 4,
    fontFamily: "Arial",
    fontSize: "15px",
};

const box =  {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

export default ResultFilterScreen;
