import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import NavHeader from './NavHeader';
import Container from '@mui/material/Container';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { WithContext as ReactTags } from 'react-tag-input';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';


let apiPrefix = ''
const isLocal = window.location.hostname === 'localhost';
if (isLocal) {
    console.log("React is running locally");
    apiPrefix = ''
} else {
    console.log("React is running on DigitalOcean");
    apiPrefix = '/app'
}

const PaperComponent = ({ alarm }) => {
    const [alarmData, setAlarmData] = useState(alarm)
    const [showModal, setShowModal] = useState(false);
    // mstrkalj edit 24-07-21
    const navigate = useNavigate();
    
    const handleSwitchChange = () => {
        var tempAlarmData = { ...alarmData, is_active: !alarmData.is_active };
        console.log("Calling " + apiPrefix + "/updateAlarm...");
        axios.put(apiPrefix + '/updateAlarm', { "alarm": tempAlarmData })
            .then((response) => {
                if (response.status === 200) {
                    setAlarmData(response.data);
                    console.log("API call /updateAlarm finished with response:", response.data);
                } else {
                    console.log("Error in API call /updateAlarm:", response.data);
                    throw new Error("Error in API call /updateAlarm");
                }
            })
            .catch((error) => {
                console.error("Error in API call /updateAlarm:", error);
            });
    };

    // after mounting printout alertData
    useEffect(() => {
        console.log("Alarm data:", alarmData);
    }, [alarmData]);

    // const handleEdit = () => {
    //     setShowModal(true);
    // }

    // const handleSave = () => {
    //     // Add your save logic here
    //     // ...
    //     setShowModal(false);
    // };

    const handleDelete = () => {
        var tempAlarmData = { ...alarmData, is_archived: true };
        console.log("Calling " + apiPrefix + "/updateAlarm with data: ", tempAlarmData);
        axios.put(apiPrefix + '/updateAlarm', { "alarm": tempAlarmData })
            .then((response) => {
                if (response.status === 200) {
                    setAlarmData(response.data);
                    console.log("API call /updateAlarm for archiving finished with response:", response.data);
                    window.location.reload();
                } else {
                    console.log("Error in API call /updateAlarm for archiving:", response.data);
                    throw new Error("Error in API call /updateAlarm for archiving");
                }
            })
            .catch((error) => {
                console.error("Error in API call /updateAlarm for archiving:", error);
            });
    };

    const handleEdit = () => {
        navigate('/alarmDetails', { state: { alarm: alarmData } });
    };

    return (
        <Paper elevation={4} style={{ height: '320px' }}>
            <div style={{ padding: '15px' }}>
                <Typography variant="h6" gutterBottom style={{ color: "#0079bf" }}>
                    {alarmData.name}
                </Typography>
                <Typography variant="body2" gutterBottom>
                    Pojmovi za alarm: {alarmData.terms.join(', ')}
                </Typography>
                {alarmData.excluding_terms.length > 0 && ( // Conditional rendering
                    <Typography variant="body2" gutterBottom>
                        Isključujući pojmovi: {alarmData.excluding_terms.join(', ')}
                    </Typography>
                )}
                <Typography variant="body2" gutterBottom>
                    E-mail adrese: {alarmData.email_addresses.join(', ')}
                </Typography>
                <Typography variant="body2" gutterBottom>
                    Kreiran dana: {alarmData.timedate_created}
                </Typography>
                <Typography variant="body2" gutterBottom>
                    Posljednji pronalazak: {alarmData.last_turned_on}
                </Typography>
            </div>
            <div style={{ padding: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body1" gutterBottom>
                    {alarmData.is_active ? 'Aktivan' : 'Neaktivan'}
                </Typography>
                <Tooltip title={alarmData.is_active ? 'Deaktiviraj alarm' : 'Aktiviraj alarm'} placement="top">
                    <Switch checked={alarmData.is_active} onChange={handleSwitchChange} />
                </Tooltip>
            </div>
            <div style={{ padding: '15px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Tooltip title="Promijeni Alarm" placement="top">
                    {/* <Link to={{
                        pathname: '/alarmDetails',
                        state: { alarm: alarmData }
                        }}>
                        <IconButton>
                            <EditNoteIcon style={{ color: '#0079bf' }} />
                        </IconButton>
                    </Link>  */}
                    <IconButton onClick={handleEdit}>
                        <EditNoteIcon style={{ color: '#0079bf' }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Obriši Alarm" placement="top">
                    <IconButton onClick={handleDelete}>
                        <DeleteIcon style={{ color: '#0079bf' }} />
                    </IconButton>
                </Tooltip>
            </div>

            
            
        </Paper>
    );
};

const AlarmScreen = () => {
    const [alarms, setAlarms] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [showDataEntryFields, setShowDataEntryFields] = useState(false);
    const [alarmName, setAlarmName] = useState('');
    const navigate = useNavigate();
    const [portalIds, setPortalIds] = useState([]);
    const [alarmLimit, setAlarmLimit] = useState(0);

    const KeyCodes = {
        comma: 188,
        enter: 13,
    };
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Check authentication first
                console.log("Calling " + apiPrefix + "/check_authentication...");
                const authResponse = await axios.get(apiPrefix + '/check_authentication', {
                    withCredentials: true,
                });
    
                if (authResponse.status === 200 && authResponse.data.message === 'User is authenticated') {
                    setIsAuthenticated(true);
                    console.log("Authenticated successfully.");
    
                    // Fetch alarms data
                    console.log("Calling " + apiPrefix + "/readAllAlarms...");
                    const alarmsResponse = await axios.get(apiPrefix + '/readAllAlarms', {
                        withCredentials: true,
                    });
                    if (alarmsResponse.status === 200) {
                        setAlarms(alarmsResponse.data);
                        console.log("Fetched alarms successfully:", alarmsResponse.data);
                    } else {
                        throw new Error('Error fetching alarms data');
                    }
    
                    // Fetch user data
                    console.log("Calling " + apiPrefix + "/user_data...");
                    const userDataResponse = await axios.get(apiPrefix + '/user_data', {
                        withCredentials: true,
                    });
                    if (userDataResponse.status === 200) {
                        setAlarmLimit(userDataResponse.data.alarm_limit);
                        console.log("Fetched user data successfully:", userDataResponse.data);
                    } else {
                        throw new Error('Error fetching user data');
                    }
                } else {
                    throw new Error('Not authenticated');
                }
            } catch (error) {
                console.error('Error fetching data on initial load:', error);
                navigate('/'); // Redirect to login if not authenticated or any error occurs
            }
        };
    
        fetchData();
    }, [navigate]);    

    const handleAddAlarmClick = async () => {
        try {
            console.log("Checking authentication...");
            const response = await axios.get(apiPrefix + '/check_authentication', {
                withCredentials: true,
            });

            if (response.status === 200) {
                setShowDataEntryFields(true);
            } else {
                navigate('/');
            }
        } catch (error) {
            console.error("Authentication check failed:", error);
            navigate('/');
        }
    };

    const handleSaveAlarm = async () => {
        try {
            const portalIdsResponse = await axios.post(apiPrefix + '/get_portal_ids_by_languages', { "languages": selectedLanguages });
            if (portalIdsResponse.status === 200) {
                setPortalIds(portalIdsResponse.data);
            } else {
                throw new Error("Error in API call /get_portal_ids_by_languages");
            }

            const list_term_names = termTags.map(tag => tag.text);
            const list_excluding_term_names = excludingTermTags.map(tag => tag.text); // Get excluding terms
            const list_email_addresses = emailTags.map(tag => tag.text);

            const data = {
                alarm_name: alarmName,
                list_portal_ids: portalIdsResponse.data,
                list_term_names,
                list_excluding_term_names, // Include excluding terms in the request
                list_email_addresses
            };

            const createAlarmResponse = await axios.post(apiPrefix + '/createAlarm', data);
            if (createAlarmResponse.status === 200 && createAlarmResponse.data === 'OK') {
                window.location.reload();
            } else {
                throw new Error("Error in API call /createAlarm");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const [emailTags, setEmailTags] = useState([]);
    const [termTags, setTermTags] = useState([]);
    const [excludingTermTags, setExcludingTermTags] = useState([]); // New state for excluding terms
    const [selectedLanguages, setSelectedLanguages] = useState([]);

    const handleEmailDelete = (i) => {
        setEmailTags(emailTags.filter((tag, index) => index !== i));
    };

    const handleEmailAddition = (tag) => {
        setEmailTags([...emailTags, tag]);
    };

    const handleTermDelete = (i) => {
        setTermTags(termTags.filter((tag, index) => index !== i));
    };

    const handleTermAddition = (tag) => {
        setTermTags([...termTags, tag]);
    };

    const handleExcludingTermDelete = (i) => { // New handler for deleting excluding terms
        setExcludingTermTags(excludingTermTags.filter((tag, index) => index !== i));
    };

    const handleExcludingTermAddition = (tag) => { // New handler for adding excluding terms
        setExcludingTermTags([...excludingTermTags, tag]);
    };

    const handleLanguageChange = (event, newLanguages) => {
        setSelectedLanguages(newLanguages);
        setTimeout(() => {
            console.log("Selected languages:", selectedLanguages);
        }, 1000);
    };

    return (
        <div>
            <NavHeader />
            <Container style={{ ...style, display: "flex", flexDirection: "column", justifyContent: "space-evenly", font: "Amalia" }}>
                {alarms.map(alarm => (
                    <div key={alarm.id} style={{ marginTop: '15px' }}>
                        <PaperComponent alarm={alarm} />
                    </div>
                ))}

                {showDataEntryFields && (
                    <Paper elevation={2} style={{ marginTop: '15px', height: '600px' }}>
                        <div style={{ padding: '15px' }}>
                            <p>Naziv alarma</p>
                            <input
                                type='text'
                                placeholder='Unesi naziv alarma'
                                style={{ height: '32px', width: '50%', marginBottom: '10px' }}
                                onChange={(e) => setAlarmName(e.target.value)}
                            />

                            <p>Odaberi zemlje za pretragu portala</p>
                            <div style={{ marginBottom: '20px', textAlign: 'left' }}>
                                <ToggleButtonGroup
                                    value={selectedLanguages}
                                    onChange={handleLanguageChange}
                                    aria-label="language-selection"
                                    color='primary'
                                >
                                    <ToggleButton value="hr" color='primary'>Hrvatski</ToggleButton>
                                    <ToggleButton value="si" color='primary'>Slovenski</ToggleButton>
                                    <ToggleButton value="ro" color='primary'>Rumunjski</ToggleButton>
                                </ToggleButtonGroup>
                            </div>

                            <p>Pojmovi za pretragu:</p>
                            <div style={{ width: '50%', marginTop: '10px' }}>
                                <ReactTags
                                    tags={termTags}
                                    delimiters={delimiters}
                                    handleDelete={handleTermDelete}
                                    handleAddition={handleTermAddition}
                                    inputFieldPosition="bottom"
                                    placeholder='Unesi pojam, zatim zarez ili Enter'
                                    autocomplete
                                    editable
                                />
                            </div>

                            <p>Isključujući pojmovi:</p> {/* New section for excluding terms */}
                            <div style={{ width: '50%', marginTop: '10px' }}>
                                <ReactTags
                                    tags={excludingTermTags}
                                    delimiters={delimiters}
                                    handleDelete={handleExcludingTermDelete}
                                    handleAddition={handleExcludingTermAddition}
                                    inputFieldPosition="bottom"
                                    placeholder='Unesi pojam za isključivanje, zatim zarez ili Enter'
                                    autocomplete
                                    editable
                                />
                            </div>

                            <p>Email adrese za dostavu alarma:</p>
                            <div style={{ width: '50%' }}>
                                <ReactTags
                                    tags={emailTags}
                                    delimiters={delimiters}
                                    handleDelete={handleEmailDelete}
                                    handleAddition={handleEmailAddition}
                                    inputFieldPosition="bottom"
                                    placeholder='Unesi email adresu, zatim zarez ili Enter'
                                    autocomplete
                                    editable
                                />
                            </div>
                        </div>
                        <br />
                        <div style={{ padding: '15px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button variant="contained" color="primary" onClick={() => window.location.reload()}>Otkaži</Button>
                            <Button style={{ marginLeft: '10px' }} variant="contained" color="primary" onClick={handleSaveAlarm}>Spremi</Button>
                        </div>
                    </Paper>
                )}
                {!showDataEntryFields && isAuthenticated && alarms.length < alarmLimit && (
                    <div style={{ marginTop: '15px', textAlign: 'center' }}>
                        <Tooltip title="Dodaj Alarm" placement="top">
                            <IconButton onClick={handleAddAlarmClick}>
                                <AddIcon style={{ color: '#0079bf' }} />
                            </IconButton>
                        </Tooltip>
                        <Typography variant="body1">Dodaj Alarm</Typography>
                    </div>
                )}
            </Container>

        </div>
    );
};

const style = {
    padding: "10px",
    fontFamily: "Arial",
    fontSize: "15px",
};

export default AlarmScreen;
