import React, { useState, useEffect } from 'react';
import NavHeader from './NavHeader';
import Typography from '@mui/material/Typography';
import { TextField, ToggleButtonGroup, ToggleButton, Button } from '@mui/material';
import { WithContext as ReactTags } from 'react-tag-input';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Divider } from '@mui/material';

let apiPrefix = '';
const isLocal = window.location.hostname === 'localhost';
if (isLocal) {
    console.log("React is running locally");
    apiPrefix = '';
} else {
    console.log("React is running on DigitalOcean");
    apiPrefix = '/app';
}

const AlarmDetails = () => {
    const { state } = useLocation();
    const { alarm } = state || {}; // Destructure the alarm from state

    const KeyCodes = {
        comma: 188,
        enter: 13,
    };
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const [alarmData, setAlarmData] = useState(alarm || {});
    const [initialData, setInitialData] = useState(alarm || {});
    const [emailTags, setEmailTags] = useState(alarm.email_addresses ? alarm.email_addresses.map(email => ({ id: email, text: email })) : []);
    const [initialEmailTags, setInitialEmailTags] = useState(alarm.email_addresses ? alarm.email_addresses.map(email => ({ id: email, text: email })) : []);
    const [termTags, setTermTags] = useState(alarm.terms ? alarm.terms.map(term => ({ id: term, text: term })) : []);
    const [initialTermTags, setInitialTermTags] = useState(alarm.terms ? alarm.terms.map(term => ({ id: term, text: term })) : []);
    const [excludingTermTags, setExcludingTermTags] = useState(alarm.excluding_terms ? alarm.excluding_terms.map(term => ({ id: term, text: term })) : []);
    const [initialExcludingTermTags, setInitialExcludingTermTags] = useState(alarm.excluding_terms ? alarm.excluding_terms.map(term => ({ id: term, text: term })) : []);
    const [selectedLanguages, setSelectedLanguages] = useState(alarm.portal_countries || []);
    const [initialSelectedLanguages, setInitialSelectedLanguages] = useState(alarm.portal_countries || []);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [portalIds, setPortalIds] = useState({});

    useEffect(() => {
        setSelectedLanguages(alarm.portal_countries || []);
        setEmailTags(alarm.email_addresses ? alarm.email_addresses.map(email => ({ id: email, text: email })) : []);
        setTermTags(alarm.terms ? alarm.terms.map(term => ({ id: term, text: term })) : []);
        setExcludingTermTags(alarm.excluding_terms ? alarm.excluding_terms.map(term => ({ id: term, text: term })) : []); // New state
        setInitialData(alarm || {});
        setInitialEmailTags(alarm.email_addresses ? alarm.email_addresses.map(email => ({ id: email, text: email })) : []);
        setInitialTermTags(alarm.terms ? alarm.terms.map(term => ({ id: term, text: term })) : []);
        setInitialExcludingTermTags(alarm.excluding_terms ? alarm.excluding_terms.map(term => ({ id: term, text: term })) : []); // New state
        setInitialSelectedLanguages(alarm.portal_countries || []);
    }, [alarm]);

    useEffect(() => {
        const isDataUnchanged =
            alarmData.name === initialData.name &&
            JSON.stringify(selectedLanguages) === JSON.stringify(initialSelectedLanguages) &&
            JSON.stringify(termTags) === JSON.stringify(initialTermTags) &&
            JSON.stringify(emailTags) === JSON.stringify(initialEmailTags) &&
            JSON.stringify(excludingTermTags) === JSON.stringify(initialExcludingTermTags); // Check excluding terms
    
        const isFormValid =
            alarmData.name && alarmData.name.length >= 3 &&
            selectedLanguages.length > 0 &&
            termTags.length > 0 &&
            emailTags.length > 0;
    
        setIsSaveDisabled(isDataUnchanged || !isFormValid);
    }, [alarmData, selectedLanguages, termTags, emailTags, excludingTermTags, initialData, initialSelectedLanguages, initialTermTags, initialEmailTags, initialExcludingTermTags]);

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const response = await axios.get(`${apiPrefix}/check_authentication`, { withCredentials: true });
                if (response.status !== 200 || response.data.message !== 'User is authenticated') {
                    throw new Error('Not authenticated');
                }
            } catch (error) {
                console.error('Error checking authentication:', error);
                window.location.href = '/'; // Redirect to login page
            }
        };

        checkAuthentication();
    }, []);

    const handleEmailDelete = (i) => {
        setEmailTags(emailTags.filter((tag, index) => index !== i));
    };

    const handleEmailAddition = (tag) => {
        setEmailTags([...emailTags, tag]);
    };

    const handleTermDelete = (i) => {
        setTermTags(termTags.filter((tag, index) => index !== i));
    };

    const handleTermAddition = (tag) => {
        setTermTags([...termTags, tag]);
    };

    const handleExcludingTermDelete = (i) => {
        setExcludingTermTags(excludingTermTags.filter((tag, index) => index !== i));
    };
    
    const handleExcludingTermAddition = (tag) => {
        setExcludingTermTags([...excludingTermTags, tag]);
    };

    const handleLanguageChange = (event, newLanguages) => {
        setSelectedLanguages(newLanguages);
        setTimeout(() => {
            console.log("Selected languages:", selectedLanguages);
        }, 1000);
    };

    const handleSave = async () => {
        try {
            // Step 1: Archive the existing alarm
            const updatedAlarm = { ...alarmData, is_archived: true };
            const updateResponse = await axios.put(`${apiPrefix}/updateAlarm`, { alarm: updatedAlarm });
    
            if (updateResponse.status !== 200) {
                throw new Error('Failed to update the alarm');
            }
    
            // Step 2: Prepare the new alarm data
            console.log("Calling "+apiPrefix+"/get_portal_ids_by_languages with languages:", selectedLanguages);
            const portalIdsResponse = await axios.post(apiPrefix + '/get_portal_ids_by_languages', { "languages": selectedLanguages });
            if (portalIdsResponse.status === 200) {
                setPortalIds(portalIdsResponse.data);
                console.log("Response from /get_portal_ids_by_languages:", portalIdsResponse.data);
            } else {
                throw new Error("Error in API call /get_portal_ids_by_languages");
            }
    
            // Step 3: Create a new alarm with the updated properties
            const newAlarm = {
                ...alarmData,
                is_archived: false,
                alarm_name: alarmData.name,
                list_term_names: termTags.map(tag => tag.text),
                list_email_addresses: emailTags.map(tag => tag.text),
                list_excluding_term_names: excludingTermTags.map(tag => tag.text), // Include excluding terms
                list_portal_ids: portalIdsResponse.data,
            };
    
            const createResponse = await axios.post(`${apiPrefix}/createAlarm`, newAlarm);
    
            if (createResponse.status === 200 && createResponse.data === 'OK') {
                window.location.href = '/alarms'; // Redirect to alarms page
            } else {
                console.log(createResponse);
                throw new Error('Failed to create the new alarm');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                window.location.href = '/'; // Redirect to login if unauthorized
            } else {
                console.error('Error saving the alarm:', error);
            }
        }
    };

    return (
        <div>
            <NavHeader />
            <div style={styles.container}>
                <Typography variant="h6" style={styles.subtitle}>Promijeni alarm</Typography>
                <Divider style={styles.divider} />
                <br />
                <br />
                <div style={styles.fieldContainer}>
                    <Typography variant="subtitle1" gutterBottom style={styles.label}>
                        Naziv alarma:
                    </Typography>
                    <TextField 
                        value={alarmData.name || ''}
                        onChange={(e) => setAlarmData({ ...alarmData, name: e.target.value })} 
                        fullWidth
                    />
                </div>
                <div style={styles.fieldContainer}>
                    <Typography variant="subtitle1" gutterBottom style={styles.label}>
                        Odaberi zemlje za pretragu portala:
                    </Typography>
                    <div style={styles.reactTagsContainer}>
                        <ToggleButtonGroup
                            value={selectedLanguages}
                            onChange={handleLanguageChange}
                            aria-label="language selection"
                            color='primary'
                        >
                            <ToggleButton value="hr">Hrvatski</ToggleButton>
                            <ToggleButton value="si">Slovenski</ToggleButton>
                            <ToggleButton value="ro">Rumunjski</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
                <div style={styles.fieldContainer}>
                    <Typography variant="subtitle1" gutterBottom style={styles.label}>
                        Pojmovi za alarm:
                    </Typography>
                    <div style={styles.reactTagsContainer}>
                        <ReactTags
                            tags={termTags}
                            delimiters={delimiters}
                            handleDelete={handleTermDelete}
                            handleAddition={handleTermAddition}
                            inputFieldPosition="bottom"
                            placeholder='Unesi pojam, zatim zarez ili Enter'
                            autocomplete
                            editable
                        />
                    </div>
                </div>
                <div style={styles.fieldContainer}>
                    <Typography variant="subtitle1" gutterBottom style={styles.label}>
                        Isključujući pojmovi:
                    </Typography>
                    <div style={styles.reactTagsContainer}>
                        <ReactTags
                            tags={excludingTermTags}
                            delimiters={delimiters}
                            handleDelete={handleExcludingTermDelete}
                            handleAddition={handleExcludingTermAddition}
                            inputFieldPosition="bottom"
                            placeholder='Unesi pojam za isključivanje, zatim zarez ili Enter'
                            autocomplete
                            editable
                        />
                    </div>
                </div>
                <div style={styles.fieldContainer}>
                    <Typography variant="subtitle1" gutterBottom style={styles.label}>
                        Email adrese za dostavu alarma:
                    </Typography>
                    <div style={styles.reactTagsContainer}>
                        <ReactTags
                            tags={emailTags}
                            delimiters={delimiters}
                            handleDelete={handleEmailDelete}
                            handleAddition={handleEmailAddition}
                            inputFieldPosition="bottom"
                            placeholder='Unesi email adresu, zatim zarez ili Enter'
                            autocomplete
                            editable
                        />
                    </div>
                </div>
                <br />
                <br />
                <div style={styles.buttonContainer}>
                    <Button variant="contained" color="primary" onClick={() => window.location.href='/alarms'}>Otkaži</Button>
                    <Button style={styles.saveButton} variant="contained" color="primary" onClick={handleSave} disabled={isSaveDisabled}>Spremi</Button>
                </div>
            </div>
        </div>
    )
};

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        fontFamily: "Arial",
        padding: "30px",
        marginLeft: "30px",
        marginTop: "30px",
    },
    subtitle: {
        marginBottom: "15px",
        color: "#6e6e6e",
    },
    fieldContainer: {
        marginBottom: "20px",
        padding: "10px",
        // border: "1px solid #ccc",
        borderRadius: "5px",
    },
    label: {
        color: "#0079bf",
        marginBottom: "5px",
    },
    reactTagsContainer: {
        width: '100%',
        marginTop: '10px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: '20px',
    },
    saveButton: {
        marginLeft: '10px',
    },
    divider: {
        marginBottom: "20px",
        backgroundColor: "#ccc", // You can customize the color
    },
};

export default AlarmDetails;