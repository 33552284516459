export const TERMS = [
    "ACI",
    "AD Plastik",
    "Adris",
    "Arena Hospitality",
    "Arenaturist",
    "Atlantic Grupa",
    "Atlantska Plovidba", 
    "Auto Hrvatska",
    "Čakovečki mlinovi",
    "Croatia osiguranje",
    "Đuro Đaković",
    "Dalekovod",
    "Professio Energia",
    "Valamar Riviera",
    "Ericsson",
    "The Garden Brewery",
    "Granolio",
    "Hoteli Brela",
    "Helios Faros",
    "Imperial Riviera",
    "HTP Korčula",
    "Liburnia Riviera",
    "Hrvatska Poštanska Banka",
    "Hrvatski Telekom",
    "HTP Orebić",
    "Ilirija",
    "INA",
    "Ingra",
    "Institut IGH",
    "Jadroplov",
    "Jadran Hoteli",
    "JANAF",
    "Jadranski naftovod",
    "Janaf",
    "Končar",
    "Luka Ploče",
    "Luka Rijeka",
    "FTB Turizam",
    "Primo Real Estate",
    "Maistra",
    "Modra Špilja",
    "Meritus",
    "Podravka",
    "Plava Laguna",
    "Quattro logistika",
    "Saponia",
    "SZIF",
    "Stanovi Jadran",
    "Sunce Hoteli",
    "Tankerska Next Generation",
    "Turisthotel",
    "Uljanik",
    "Alpha Adriatic",
    "Viro",
    "obveznica",
    "obveznice",
    "narodna obveznica",
    "narodne obveznice",
    "korporativna obveznica",
    "korporativne obveznice",
    "Mezzanine",
    "Prosperus",
    "ASEF",
    "Invera",
    "private equity fond",
    "fond rizičnog kapitala",
    "VC",
    "venture fond",
];
  