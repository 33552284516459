import React, { useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { TextField, Button, Grid, Paper, Typography } from "@mui/material";

let apiPrefix = ''
const isLocal = window.location.hostname === 'localhost';
if (isLocal) {
  console.log("React is running locally");
  // apiPrefix = 'http://localhost:5000';
  apiPrefix = ''
} else {
  console.log("React is running on DigitalOcean");
  apiPrefix = '/app'
}

const LoginScreen = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("This is the url to be called: ", apiPrefix+'/login');
      const response = await axios.post(apiPrefix + '/login', { "username": email, "password": password });
      // console.log("API call to /app/login finished with response:", response.data);
      if (response.status === 200) {
        if (response.data === null) {
          console.log("API returned null.. login canceled")
        }
        else if (response.data !== null){
          console.log("Sucessfuly logged. Redirecting to /main...")
          // console.log(response.data)
          navigate("/main");
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        setErrorMessage("Pogrešno korisničko ime ili lozinka");
      } else if (error.request) {
        console.error("Nema odgovora sa servera");
      } else {
        console.error("Greška kod postavljanja zahtjeva: ", error.message);
      }
    }
  }

  return (
    <Grid
      container
      component={Paper}
      elevation={6}
      square
      style={{ height: "100vh" }}
      justify="center"
      alignItems="center"
    >
      <div style={{ margin: "64px" }}>
        <Typography variant="h2" gutterBottom>
          Prijava
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email adresa"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          {errorMessage && (
            <Typography variant="body2" color="error">
              {errorMessage}
            </Typography>
          )}
          
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Lozinka"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: "32px" }}
          >
            Prijava
          </Button>
        </form>
      </div>
    </Grid>
  );
};

export default LoginScreen;